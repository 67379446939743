<template>
  <div id="app">
    <HelloWorld @isclick="dialogVisible = true" :school_id="school_id" />
    <el-dialog
      title="请输入园区ID"
      :visible.sync="dialogVisible"
      width="30%"
      :modal-append-to-body="false"
      center
    >
      <el-input type="text" v-model="value" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialog">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import HelloWorld from "./components/HelloWorld.vue";

export default {
  name: "App",
  data() {
    return { dialogVisible: false, school_id: "", value: "" };
  },
  methods: {
    dialog() {
      this.dialogVisible = false;
      this.school_id = this.value;
      localStorage.setItem("school_id", this.school_id);
    },
  },
  created() {
    this.school_id = localStorage.getItem("school_id");
  },
  components: {
    HelloWorld,
  },
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
}
html,
body {
  height: 100%;
  width: 100%;
  font-size: 1vw;
}
#app {
  width: 100%;
  height: 100%;
  padding: 0 2vh;
  box-sizing: border-box;
}
#app {
  width: 100%;
  height: 100%;
  padding: 0 2vh;
  box-sizing: border-box;
}
</style>
